* {
  font-family: 'Quicksand', sans-serif;
}

td input {
  max-width: 80px;
  text-align: center;
}

th,
td {
  text-align: center;
}

.error {
  color: red;
  font-weight: bold;
}


